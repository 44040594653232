import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineStar, AiOutlinePlus } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bed from "./../../../src/assets/images/singlebed.png";
import "./NewlyAddedHostel.css";
import axios from "axios";
import { endpoints } from "../../Services/endpoints";
const NewlyAddedHostelCard = (props) => {
    const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { data, image, feature=[], rating=[] } = props;
  console.log(image, "Images coming from props");
  const newFeatures = feature.slice(0, 5);
  // const newImg = image.slice(0, 1);
  const handleViewDetails = () => {
    if (token) {
      navigate(`/hotsel-detail/${data?.id}`);
    } else {
      toast("Please Login ", { type: "warning" });
      navigate("/login-signup");
    }
  };

  return (
    <>
      <div className="container-cards second-row-card">
        <div className="hostel-cards-col">
        <div className="media-img">
          {data?.hostel_membership && (
            <div className="ribbon">
              <span className="ribbon3">{data.hostel_membership}</span>
            </div>
          )}
          {data.hostel_images?.length ? (
            <img src={data.hostel_images[0] ||data.hostel_images[1]} alt="Hostel" />
          ) : (
            <Skeleton height={250} variant="rectangular" />
          )}
        </div>
          <div className="hostel-details">
            <h3> {data?.hostel_name} </h3>
            <h6>
              <span className="home-lockation-txt">
                {data?.hostel_address}, {data?.city}
              </span>
              {rating.map((itm, idx) => ( 
              <span
                   key={idx}
                className="home-rating"
              >
                <span className="rating-point">
                   {itm.average_rating} 
                </span>
                <span className="home-rating-star">
                  <AiOutlineStar />
                </span>
              </span>
               ))} 
            </h6>
          </div>
          <div className="hostel-facility-details">
            <ul className="facility-text">
               {newFeatures.map((item, index) => ( 
              <li
                key={index}
              >
                <span>
                  <TiTick />
                </span>
                {item} 
              </li>
              ))} 
              <li>
                <Link
                  onClick={handleViewDetails}
                >
                  View More
                  <span className="btn-pluse">
                    <AiOutlinePlus />
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="hostel-bed-avility-details">
            <h6>
              <span className="bed-avl-txt">Rent From</span>
              <span className="home-card-rating">
                <button
                  className="book-now-home-btn"
                     onClick={handleViewDetails}
                >
                  Book Now
                </button>
              </span>
            </h6>
            <ul>
               {data?.single_bed_rent && ( 
              <li>
                <img src={Bed} alt="Single Bed" />
                &nbsp;
                <span className="hostel-bed-price">
                  Rs.
                   {data?.single_bed_rent}/- 
                </span>
              </li>
               )} 
               {data?.double_bed_rent && ( 
              <li>
                <img
                  src={Bed}
                  //
                  alt="Double Bed"
                />
                <img src={Bed} alt="Double Bed" />
                &nbsp;
                <span className="hostel-bed-price">
                  Rs.
                   {data?.double_bed_rent}/- 
                </span>
              </li>
               )} 
               {data?.triple_bed_rent && ( 
              <li>
                <img src={Bed} alt="Triple Bed" />
                <img src={Bed} alt="Triple Bed" />
                <img src={Bed} alt="Triple Bed" />
                &nbsp;
                <span className="hostel-bed-price">
                  Rs.
                  {data?.triple_bed_rent}/- 
                </span>
              </li>
               )} 
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
const NewlyAddedHostel = () => {
  const [hostels, setHostels] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const newHostel = hostels.slice(0, 8);

  // Fetch hostels data using useCallback
  const fetchHostels = useCallback(async () => {
    setLoading(true);
    try {
      const newlyHostelApi = endpoints.home.newlyAddedHostel;
  const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  // Authorization: `Bearer ${token}`,
};
      const response = await axios.get(newlyHostelApi, { headers: headers });
      if (response.data.status === 1) {
        setHostels(response.data.data);
        // console.log(response.data.data, "All newly Added hostel data");
      }
    } catch (err) {
      console.error(
        "Failed to fetch newly added hostels. Please try again later.",
        err
      );
    } finally {
      setLoading(false); // Stop loading
    }
  }, [token]);

  useEffect(() => {
    fetchHostels();
  }, [fetchHostels]);

  return (
    <>
      <div className="container-fluid p-0 popular-cont">
        <div className="_category-page-all-hostels-section material-shadow-card-light">
          <div className="_block-header">
            <div className="category-page-list-heading">Latest Hostel / PG</div>
          </div>
          <div className="row">
            {newHostel.map((itm, idx) => {
              console.log(itm,"all new data ")
              return (
                <>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="item city-name">
                      <NewlyAddedHostelCard 
                      data={itm}
                      images={itm.hostel_images}
                      feature={itm.hostel_features}
                      rating={itm.reviews}
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default NewlyAddedHostel;
