import React, { useState } from "react";
import { endpoints } from "../../Services/endpoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VerifyedOtp = (props) => {
  const {
    

    setShowLoginForm,
    showLoginForm,
    showSignupForm,
    setShowSignupForm,
    setShowVerifyOtp,
    handleSignupClick
  } = props;

  const [buttonColor, setButtonColor] = useState("lightgrey");
  const [cursor, setCursor] = useState("no-drop");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const storedPhoneNumber = localStorage.getItem("phoneNumber");
  const lastFourDigits = storedPhoneNumber ? storedPhoneNumber.slice(-4) : "****";
  const handleOtpClick = () => {
    const verifyOtpUrl = endpoints.home.verifyOtp;
     
   
   

    if (otp.length !== 4) {
      return;
    }
    setLoading(true);
    const data = {
      phone: storedPhoneNumber,
      otp: otp,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    
    axios
      .post(verifyOtpUrl, data, { headers: headers })
      .then((res) => {
        if (res.data.status === 1) {
          setShowVerifyOtp(false);
          setShowLoginForm(false);
          setShowSignupForm(true);
          toast(res.data.message, { type: "success" });
          setLoading(false);
          console.log(res.data.message, "Submitted successfully");
        } else if (res.data.status === 2) {
          const responseData = res.data.student[0];
          // localStorage.setItem("userdata", responseData);
        
          // localStorage.setItem("userdata", JSON.stringify(responseData));
          if (responseData && responseData.token) {

            console.log("Response Data:", responseData);
            localStorage.setItem("userdata", JSON.stringify(responseData));
            toast(res.data.message, { type: "success" });
            const token = responseData.token;
  
            localStorage.setItem("token", token);
            console.log(token, "user token");
            setShowVerifyOtp(false);
            setShowLoginForm(false);
            setShowSignupForm(false);
            setLoading(false);
            navigate("/");
          } else {
            console.log("Token not found in response data");
            setLoading(false);
          }
         
         
        } else {
          toast(res.data.message, { type: "error" });
        }
      })
      .catch((err) => {
        console.log(err, "Error occurred while verifying OTP");
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 4);
    setOtp(inputValue);

    // Update button color to green if the input is valid
    setButtonColor(inputValue.length === 4 ? "#15124b" : "lightgrey");
    setCursor(inputValue.length === 4 ? "pointer" : "no-drop");
  };


  const handleResendOtpClick = () => {
    // Add logic here to resend OTP
    handleSignupClick()
    toast("Resent OTP successfully", { type: "success", toastId: "resendOtpSuccess" });
  };


  return (
    <>
      <div className="login-form-cont-head">
        <h1>Verify OTP</h1>
      </div>
      <div className="login-form-cont-main">
        <p>Enter Your OTP <span style={{ color: "red" }}>*</span></p>
        <input
          type="number"
          placeholder="OTP"
          className="input-field"
          maxLength={4}
          value={otp}
          onChange={handleInputChange}
        />

        <br />
        <br />
        <button
          className="login-button"
          type="button"
          disabled={loading || otp.length !== 4}
          onClick={handleOtpClick}
          style={{
            display: showSignupForm ? "none" : "inline-block",
            backgroundColor: buttonColor,
            cursor: cursor,
          }}
         
        >
          {loading ? "Verifying..." : "Verify OTP"}
        </button>
        <button className="resend-otp-otp" onClick={handleResendOtpClick}>Resend Otp</button>
        <p>Last 4 digits of your phone number: {lastFourDigits}</p>
        
        <ToastContainer />
      </div>
    </>
  );
};

export default VerifyedOtp;
