import React, { useEffect, useState } from "react";
import "./HomeScreens.css";

import Locations from "../Home/Location/Locations";
import SearchBar from "../Home/SearchBar/SearchBar";

import HostelCity from "../Home/HostelCIty/HostelCity";
import Footer from "../Common/Footer/Footer";

import HostelPackages from "../Home/HostelPackages/HostelPackages";
import useGeoLocation from "../Hooks/useGeoLocation";

import axios from "axios";
import HomeHeader from "../Common/HomeHeader/HomeHeader";
import FooterSticky from "../Common/FooterSticky/FooterSticky";

import Loader from "../utils/Loader";
import LocationByHostel2 from "../Home/LocationByHostel/LocationByHostel2";
import PopularHostel from "../Home/HomeSuggestionHostels/PopularHostel";
import Banner2 from "../Home/Banner/Banner2";
import Banner from "../Home/Banner/Banner";
import NewlyAddedHostel from "../Home/NewlyAddedHostel/NewlyAddedHostel";

const HomeScreens = () => {
  const location = useGeoLocation(); 
  
  const [nearHosteldata, setNearHostelData] = useState([]);
  const [shortsFeatures, setShortsFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  // const token = localStorage.getItem("token");

  const NearHostel = () => {
    console.log("Attempting to fetch geolocation...");
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  
        const nearhostelUrl = `https://admin.nearmehostel.com/api/hostels/nearest-hostels?latitude=${latitude}&longitude=${longitude}`;
        console.log(`Fetching data from: ${nearhostelUrl}`);
  
        const headers = {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
          // Authorization: `Bearer ${token}`
        };
  
        axios.get(nearhostelUrl, { headers: headers })
          .then((res) => {
            // console.log("Response received:", res);
            if (res.data.success  === 1) {
              const nearHostelVal = res.data.data;
              console.log("Near Hostel Data:", nearHostelVal);
  
              const shortFeatures = nearHostelVal[0]?.hostel_features; // Corrected variable name
              const shortsFeatures = shortFeatures[0]?.shortsFeatures; // Corrected variable name
  
              setShortsFeatures(shortsFeatures);
              setNearHostelData(nearHostelVal);
              setLoading(false);
            } else {
              console.error("API status is not 1, check response:", res);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.error("Error fetching nearest hostels:", err);
            setLoading(false);
          });
      }, (error) => {
        console.error("Error obtaining geolocation:", error);
        setLoading(false);
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  };

  useEffect(() => {
    NearHostel();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HomeHeader />
           <div className="layout__container">
            <div className="homePage__container">
              <Locations />
              <SearchBar />

              <LocationByHostel2
                nearHosteldata={nearHosteldata}
                shortsFeatures={shortsFeatures}
              /> 

               {/* <Banner2 /> */}
               <Banner />
              <PopularHostel />
        <NewlyAddedHostel/>
              <HostelPackages />
              <HostelCity />
              <Footer />
            </div>
          </div>
          <FooterSticky /> 
        </>
      )}
    </>
  );
};

export default HomeScreens;